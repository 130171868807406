@font-face {
  font-family: 'Farro';
  src: url('../fonts/Farro/Farro-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Tommy';
  src: url('../fonts/Tommy/tommy-soft-regular.otf') format('opentype');
}

@font-face {
  font-family: 'Tommy-Light';
  src: url('../fonts/Tommy/tommy-soft-light.otf') format('opentype');
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6,
.h7,
p,
.navbar,
.brand,
a,
.td-name,
td {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: 'Tommy', 'Helvetica', Arial, sans-serif !important;
  // font-family: 'Tommy-Light';
}
.btn,
.navbar .navbar-nav > li > a.btn {
  font-size: 15px;
}

.font-color {
  &--black {
    color: black !important;
  }
}

.navbar {
  // background-color: rgba(249, 228, 183, 0.2);
  // background-color: rgba(238, 238, 238, 0.3)
  // border-bottom: 0.005rem solid lightgrey;
  padding: 0rem;
}

body,
a {
  // line-height: 100%;
}

.mail-chimp-container {
  // margin: 0rem 2rem;
  display: flex;
  justify-content: center;
  background-color: #fffcf5;
  color: black !important;
}

.mail-chimp-container #mc_embed_signup {
  background-color: #fffcf5 !important;
}

#mc_embed_signup {
  width: 60vw;
}

#mc_embed_signup .foot {
  width: auto !important;
}

.mail-chimp-container-header {
  font-weight: bold;
  font-size: 2rem;
  text-align: center;
  color: black;
}

.mail-chimp-container-submit {
  display: flex;
  justify-content: center;
}

.mail-chimp-container-submit-btn {
  background-color: black !important;
  font-weight: bold !important;
}

.body-text {
  font-size: 1.75rem !important;
  color: #66615b;
  font-family: 'Tommy-Light', 'Helvetica', Arial, sans-serif !important;
}

.BrainhubCarousel {
  display: block !important;
}

.BrainhubCarouselItem {
  display: block !important;
}

.company-logo-font {
  font-family: 'Tommy', Arial, sans-serif !important;
  font-size: 3rem;
  color: #66615b !important;
  margin: 0px 7px 5px 7px;
}

.company-logo {
  height: 3rem;
  width: auto;
  // position: absolute;
  // top: -3rem;
}

.company-values-heading {
  font-weight: bolder;
}

.company-values-heading {
  font-weight: 400;
}

.discount-banner {
  background-color: linen;
  font-size: 1.25rem;
}

.navbar .navbar-brand {
  padding: 15px 7px !important;
}

.navbar .navbar-nav > li > a {
  height: 100% !important;
}

.company-logo-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-right: 2rem;
}

.container {
  margin: 0 !important;
  width: 100% !important;
}

.banner {
  margin: 2rem 0rem !important;
  border-top: lightgray solid 0.1rem;
  border-bottom: lightgray solid 0.1rem;
}

.banner-padding {
  margin: 1 0rem;
}

.navbar-container {
  padding: 0 10rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  // justify-content: space-between;
}

.feature-section {
}

.feature-section-image {
  height: 50rem;
  width: auto;
}

.feature-section-square-image {
  height: 100% !important;
  object-fit: contain !important;
}

.clearfix:after {
  content: '';
  display: table;
  clear: both;
}

.landing-alert {
  margin-bottom: 0;
}

.how-it-works-section-image-cover {
  position: relative;
  height: auto;
  width: 50rem;
  overflow: hidden;
  // border-radius: 50%;
}

.how-it-works-image {
  height: 70vh;
  width: auto;
}

.how-it-works-section-pane {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 570px;
  width: 35%;
  height: 90vh;
}

.how-it-works-heading {
  top: 0rem;
}

.section {
  padding: 2rem 0;
  position: relative;
}

.section-pane {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 570px;
  width: 35%;
}
.section-image {
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
}

.section-image-cover {
  position: relative;
  height: 50rem;
  width: 50rem;
  overflow: hidden;
  // border-radius: 50%;
}

.section-image-cover-border {
  border-radius: 50%;
}

.section-image-cover img {
  width: 100%;
  height: auto;
}

.section-image-round {
  // height: 50%;
  // width: 50%;
  // border-radius: 50%;
}

.section-content {
  font-size: 2rem;
  color: black;
}

.discount-cover {
  height: 100%;
  width: 100%;
  // opacity: 0.4;
}

.paragraph-remove-padding {
  line-height: 1rem !important;
}

.font-color {
  &--black {
    color: black;
  }

  &--brown {
    color: brown;
  }

  &--modern-beige {
    color: #e8cda3 !important;
  }

  &--beige {
    color: beige !important;
  }

  &--vanilla {
    color: #fffcf5 !important;
  }

  &--white {
    color: white !important;
  }

  &--red {
    color: #da5556 !important;
  }

  &--light-pink {
    color: lightpink !important;
  }

  &--yellow {
    color: yellow !important;
  }

  &--green {
    color: green !important;
  }

  &--purple {
    color: blueviolet !important;
  }

  &--orchid {
    color: orchid !important;
  }
}

.opacity-cover {
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: 0.4;

  &--modern-beige {
    // background-color: #edc496 !important;
    background-color: #e8cda2 !important;
  }

  &--black {
    background-color: black;
  }

  &--brown {
    background-color: brown;
  }

  &--beige {
    background-color: beige !important;
  }

  &--vanilla {
    background-color: #fffcf5 !important;
  }

  &--white {
    background-color: white !important;
  }

  &--red {
    background-color: #da5556 !important;
  }

  &--light-pink {
    background-color: lightpink !important;
  }

  &--yellow {
    background-color: yellow !important;
  }

  &--green {
    background-color: green !important;
  }

  &--purple {
    background-color: blueviolet !important;
  }

  &--orchid {
    background-color: orchid !important;
  }

  &--grey {
    background-color: #828282 !important;
  }
}

.landing-section-content {
  z-index: 99;
}

.landing-section-btn {
  color: black !important;
  border-color: black !important;
}

.landing-image {
  width: 70vw;
  min-height: 570px;
  object-fit: cover;
}

.blur-top {
  width: 100%;
  position: absolute;
  bottom: 0px;
  top: 0px;
  box-shadow: inset 0 4rem 4rem -7px white;
}

.blur-right {
  width: 100%;
  position: absolute;
  bottom: 0px;
  top: 0px;
  box-shadow: inset -4rem 0 4rem -7px white;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1vh 5vw;
}

.landing-header {
  width: 70vw;
  min-height: 570px;
  background-repeat: no-repeat;
  // // height: 80vh;
  position: relative;
  object-fit: cover;

  &-full-screen-container {
    position: relative;
    display: flex;
    flex-wrap: wrap;
  }

  &--split-screen-container {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    // height: 80vh;
    width: 100vw;
    justify-content: center;
  }

  &-pane {
    position: relative;
  }

  &-full-screen {
    position: absolute;
    text-align: left;
    z-index: 3;
    top: 25%;
    right: 7.5%;
    color: black;
  }
}

.product-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.product-tile-container {
  width: 30rem;
  padding: 2rem;
  margin: 0rem 3rem;
}

.product-image {
  height: 25rem;
  width: 25rem;
  object-fit: contain;
  border-radius: 50%;
}

.product-tile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.product-tile-footer {
  text-align: center;
}

.landing-section-container {
  width: auto;
  height: 75vh;
  display: flex;
  justify-content: center;
}

.landing-section .btn-simple {
  padding: 0;
}

.landing-section .column {
  padding: 0 75px 0 25px;
}

.team-player .img-circle,
.team-player .img-thumbnail {
  display: block;
  margin-top: 50px;
  margin-left: auto;
  margin-right: auto;
  width: 120px;
}

.contact-section-container {
  // display: flex;
  // flex-direction: row;
  width: 100%;
  // justify-content: space-evenly;
  padding: 5rem;
  color: black;
}
.contact-section {
  padding: 0rem 10rem;
}
.contact-form {
  margin-top: 30px;
}

.contact-form label {
  margin-top: 15px;
}

.contact-form .btn {
  margin-top: 30px;
}

.navbar-relative {
  position: relative;
  z-index: 2;
}

.navbar-item {
  color: #66615b !important;
  font-size: 1.8rem;
  padding: 0rem 1rem;
  // font-family: "Tommy";
}

#register-navbar a {
  color: #fff;
}

.register-background {
  background-image: url('../paper_img/landscape.jpg');
  background-position: center center;
  background-size: cover;
  min-height: 100vh;
  overflow: hidden;
  position: absolute;
  width: 100%;
}

.register-background .filter-black::after {
  background-color: rgba(0, 0, 0, 0.5);
  content: '';
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}

.register-background .container {
  margin-top: 11%;
  position: relative;
  z-index: 3;
}

.register-footer {
  bottom: 20px;
  color: #fff;
  position: absolute;
  z-index: 3;
  width: 100%;
}

.register-footer .fa-heart {
  color: #eb5e28;
}

.register-card {
  background-color: #ff8f5e;
  border-radius: 8px;
  color: #fff;
  max-width: 350px;
  margin: 20px 0 70px;
  min-height: 400px;
  padding: 30px;
}

.register-card label {
  margin-top: 15px;
}

.register-card .title {
  color: #b33c12;
  text-align: center;
}

.register-card .btn {
  margin-top: 30px;
}

.register-card .forgot {
  text-align: center;
}

.profile-background {
  background-image: url('../paper_img/lamp.jpg');
  background-position: center center;
  background-size: cover;
  height: 300px;
  position: relative;
  margin-top: -110px;
}

.profile-background .filter-black::after {
  background-color: rgba(0, 0, 0, 0.5);
  content: '';
  display: block;
  height: 300px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}

.profile-content {
  position: relative;
}

.owner .avatar {
  margin-top: -85px;
  padding: 15px;
  position: relative;
  z-index: 3;
}

.owner .name h4 {
  margin-top: 10px;
}

.profile-tabs {
  margin: 50px 0;
  min-height: 300px;
}

#following h3 {
  margin: 40px 0;
}

.follows .unfollow {
  width: 15px;
}

.follows hr {
  margin-top: 10px;
}

.alert h5 {
  margin-bottom: 10px;
}

.footer {
  border-top: 1px solid lightgrey;
  margin: 0rem 3rem;
  padding: 1rem;
  text-align: right;
  font-family: 'Tommy';
}

@media only screen and (max-width: 491px) {
  body {
    background-color: white;
  }

  h1 {
    font-size: 3.5rem;
  }

  h2 {
    font-size: 2.5rem;
  }

  h3 {
    font-size: 1.5rem;
  }

  p {
    font-size: 1.25rem;
  }

  .contact-section {
    padding: 0rem 5rem;
  }

  .section-pane {
    min-height: 25rem;
  }

  .section-pane {
    width: auto;
  }

  .landing-header {
    background-size: 50rem;
    min-height: 25rem;

    &--split-screen-container {
      justify-content: center;
      text-align: center;
      height: 50%;
      flex-wrap: nowrap;
    }
  }

  .product-row {
    flex-direction: column;
  }

  .product-tile-container {
    width: auto;
  }

  .section-content {
    padding: 0rem;
  }

  .opacity-cover {
    position: unset;
    height: 0rem;
    width: 0rem;
    background-color: none;
    opacity: 1;
  }
}

@media only screen and (max-width: 850px) {
  .company-logo {
    height: 2rem;
  }

  .company-logo-font {
    font-size: 2rem;
  }

  .navbar-item {
    font-size: 1rem;
    display: none;
  }

  .landing-section-container {
    width: auto;
    display: block;
  }

  .landing-header {
    width: auto;

    .landing-image {
      width: 100vw;
      height: auto;
      object-fit: inherit;
    }
  }

  .landing-header-full-screen {
    right: 2vw;
    top: 20vh;
  }

  .landing-header--split-screen-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .how-it-works-section-pane {
    height: auto;
    min-height: auto;
  }

  .how-it-works-image {
    height: auto;
    width: 100vw;
    object-fit: scale-down;
  }

  .section-image-cover {
    width: auto;
  }

  .how-it-works-content-pane {
    order: 1 !important;
  }

  .how-it-works-picture-pane {
    order: 2 !important;
    height: auto;
  }

  .how-it-works-section-image-cover {
    width: auto;
  }

  .blur-right {
    display: none;
  }

  .blur-top {
    display: none;
  }

  .container {
    padding-left: 0rem;
    padding-right: 0rem;
    margin-left: 0rem;
    margin-right: 0rem;
  }

  .content-section-pane {
    order: 1;
  }

  .image-section-pane {
    order: 2;
  }

  .how-it-works-section-pane {
    width: 75%;
  }

  .section-content {
    padding: 0rem 2rem;
  }

  .section-image-cover img {
    width: 80%;
  }

  .section-image-cover {
    height: auto;
  }

  // .contact-subheading {
  //   font-size:1.2rem !important;
  // }

  // .contact-email {
  //   font-size:1.2rem !important;
  // }

  .body-text {
    font-size: 1.5rem !important;
  }

  .footer {
    margin: 0rem;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1000px) and (-webkit-min-device-pixel-ratio: 1) {
  .section-content {
    text-align: center;
    padding: 7.5rem 2rem;
  }

  .section-pane {
    width: 50%;
    min-height: auto;
  }

  .section-image-cover {
    display: flex;
    justify-content: center;
  }

  .how-it-works-section-image-cover {
    width: auto;
  }
}
